/**
 *    SPDX-License-Identifier: Apache-2.0
 */

const namespaces = 'tables';

const BLOCK_LIST = `${namespaces}/BLOCK_LIST`;
const CHAINCODE_LIST = `${namespaces}/CHAINCODE_LIST`;
const CHANNELS = `${namespaces}/CHANNELS`;
const PEER_LIST = `${namespaces}/PEER_LIST`;
const TXN_LIST = "TXN_LIST";
const BLOCK_SEARCH = "BLOCK_SEARCH";
const CHAINCODE_META_DATA = 'CHAINCODE_META_DATA';
const TRANSACTION = `${namespaces}/TRANSACTION`;
const TRANSACTION_LIST = `${namespaces}/TRANSACTION_LIST`;
const BLOCK_LIST_SEARCH = `${namespaces}/BLOCK_LIST_SEARCH`;
const BLOCK_RANGE_SEARCH = 'BLOCK_RANGE_SEARCH';
const BLOCK_RANGE_LOADED = `${namespaces}/BLOCK_RANGE_LOADED`;
const TRANSACTION_LIST_SEARCH = `${namespaces}/TRANSACTION_LIST_SEARCH`;
const ORGS = `${namespaces}/ORGS`;

export default {
  BLOCK_LIST,
  CHAINCODE_LIST,
  CHANNELS,
  PEER_LIST,
  TXN_LIST, 
  BLOCK_SEARCH,
  CHAINCODE_META_DATA,
  TRANSACTION,
  TRANSACTION_LIST,
  BLOCK_LIST_SEARCH,
  BLOCK_RANGE_SEARCH,
  BLOCK_RANGE_LOADED,
  TRANSACTION_LIST_SEARCH,
  ORGS,
};
